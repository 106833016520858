import React, { useState, useEffect, useMemo } from 'react'
import uniq from 'lodash/uniq'
import isEmpty from 'lodash/isEmpty'
import flatten from 'lodash/flatten'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { useSession } from '@slerp/accounts'
import { useMutation, useQuery } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'
import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Radio from 'antd/lib/radio'
import Space from 'antd/lib/space'
import Input from 'antd/lib/input'
import Switch from 'antd/lib/switch'
import Select from 'antd/lib/select'
import Tooltip from 'antd/lib/tooltip'
import Divider from 'antd/lib/divider'
import message from 'antd/lib/message'
import Typography from 'antd/lib/typography'
import PageHeader from 'antd/lib/page-header'
import DatePicker from 'antd/lib/date-picker'
import {
  PoundOutlined,
  PercentageOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import { filterSelectOption } from '@slerp/antd-utils'
import { RadioChangeEvent } from 'antd/lib/radio'
import { Store as FormStore } from 'antd/lib/form/interface'
import { descriptionText } from './Helpers'
import {
  endDateRule,
  requiredRule,
  startDateRule,
  discountCodeRule,
  percentageValueRule,
  locationMinOrderValueRule
} from './Rules'
import {
  GET_DISCOUNT,
  CREATE_DISCOUNT,
  UPDATE_DISCOUNT,
  GET_MERCHANT_STORES
} from './Actions'
import Products from './Products'
import Categories from './Categories'
import useGetMerchantDeliveryPricingBands from 'components/CustomHooks/DeliveryPricingbands/useGetMerchantDeliveryPricingBands'
import { formatCategoryValues, formatProductValues } from './utils'
import FulfillmentTypeSelect from './FulfillmentTypeSelect'
import { toStringList } from './utils'
import Loading from './../Utils/Loading'
import errorReducer from 'errors/errorReducer'
import { CustomModal } from 'components/Widgets'
import { SelectDownArrowIcon } from '@slerp/assets'
import { onFocusScrollNumber, onBlurScrollNumber } from 'components/Utils/price'

interface Store {
  id: string
  slug: string
  name: string
}

interface StoreSwitchOption {
  children: string
  key: string
  value: string
}

const { useForm } = Form
const { Paragraph, Text } = Typography
const { TextArea } = Input

const DISCOUNT_HELP_URL =
  'https://support.slerp.com/knowledge/creating-and-managing-discount-codes'
const ADDITIONAL_FEE_URL = 'https://support.slerp.com/knowledge/additional-fee'
const TIPPING_URL = 'https://support.slerp.com/knowledge/tipping'

const PERCENTAGE_INPUT_PARAMS = {
  type: 'number',
  style: { width: 150 },
  addonAfter: <PercentageOutlined />,
  min: 0,
  step: 0.01,
  onFocus: onFocusScrollNumber,
  onBlur: onBlurScrollNumber
}

const CURRENCY_INPUT_PARAMS = {
  type: 'number',
  style: { width: 150 },
  addonBefore: <PoundOutlined />,
  min: 0,
  step: 0.01,
  onFocus: onFocusScrollNumber,
  onBlur: onBlurScrollNumber
}

const defaultFulfillmentTypes = ['delivery', 'pickup', 'order_at_table']

const initialFormValues = {
  target: 'all_products',
  type: 'percentage',
  start_date: moment(),
  fulfillment_types: defaultFulfillmentTypes
}

const FULFILLMENT_TYPE_OPTIONS = [
  {
    label: 'Delivery',
    value: 'delivery',
    disabled: false
  },
  {
    label: 'Pickup',
    value: 'pickup',
    disabled: false
  },
  {
    label: 'Order at table',
    value: 'order_at_table',
    disabled: false
  }
]

const DiscountForm = ({
  setDrawerVisibility
}: {
  setDrawerVisibility: (visible: boolean) => void
}) => {
  const { merchant } = useSession()
  const { discount_id } = useParams()
  const [form] = useForm()
  const history = useHistory()
  const [createDiscount, { loading: isLoadingCreateDiscount }] = useMutation(
    CREATE_DISCOUNT,
    {
      fetchPolicy: 'no-cache',
      refetchQueries: ['merchantDiscounts']
    }
  )
  const [updateDiscount, { loading: isLoadingUpdateDiscount }] = useMutation(
    UPDATE_DISCOUNT,
    {
      fetchPolicy: 'no-cache',
      refetchQueries: ['merchantDiscounts']
    }
  )

  // try to fetch when given a particular discount code
  const shouldSkipGetDiscountQuery = !discount_id
  const { data, loading } = useQuery(GET_DISCOUNT, {
    skip: shouldSkipGetDiscountQuery,
    variables: { discount_id: discount_id, slug: merchant.slug },
    fetchPolicy: 'no-cache'
  })

  const { data: storesData } = useQuery(GET_MERCHANT_STORES, {
    variables: { merchantId: merchant.id },
    fetchPolicy: 'no-cache',
    onCompleted: (storesData) => {
      const { stores } = storesData
      setStores(stores)
    }
  })
  const [deliveryPricingBands] = useGetMerchantDeliveryPricingBands({
    merchantId: merchant.id
  })
  const discountProfile = data && data.discounts[0]
  const [type, setType] = useState<string>('percentage')
  const [typeValue, setTypeValue] = useState<number>(0)
  const [showNumberOfUsesLimit, setShowNumberOfUsesLimit] =
    useState<boolean>(false)
  const [
    showNumberOfUsesPerCustomerLimit,
    setShowNumberOfUsesPerCustomerLimit
  ] = useState<boolean>(false)
  const [showMinimumOrderValue, setShowMinimumOrderValue] =
    useState<boolean>(false)
  const [showDurationLimit, setShowDurationLimit] = useState<boolean>(false)
  const [isActive, setIsActive] = useState(false)
  const [isAutomatic, setIsAutomatic] = useState(false)
  const [stores, setStores] = useState<Array<Store>>([])
  const [target, setTarget] = useState(
    discountProfile?.target || 'all_products'
  )
  const [showStoreLimit, setShowStoreLimit] = useState<boolean>(false)
  const [storeIds, setStoreIds] = useState<string[]>([])
  const [fulfillmentTypes, setFulfillmentTypes] = useState(
    FULFILLMENT_TYPE_OPTIONS
  )

  const action = discountProfile ? 'update' : 'create'
  const merchantStoresDeliveryPricingBands = useMemo(() => {
    return Object.keys(deliveryPricingBands).filter((storeId: string) => {
      const { sameday = [], preorder = [] } = deliveryPricingBands[storeId]
      return !!(sameday || []).length || !!(preorder || []).length
    })
  }, [deliveryPricingBands])

  const locationLimitWithDeliveryPricingBands = useMemo(() => {
    const selectedStoresWithDeliveryPricingBands = storeIds.filter(
      (storeId: string) => merchantStoresDeliveryPricingBands.includes(storeId)
    )

    return stores.filter(({ id }) =>
      selectedStoresWithDeliveryPricingBands.includes(id)
    )
  }, [
    stores,
    storeIds,
    deliveryPricingBands,
    merchantStoresDeliveryPricingBands
  ])

  if (discountProfile && discountProfile.start_date) {
    discountProfile.start_date = moment(discountProfile.start_date)
  }
  if (discountProfile && discountProfile.published_at) {
    discountProfile.published_at = moment(discountProfile.published_at)
  }
  if (discountProfile && discountProfile.end_date) {
    discountProfile.end_date = moment(discountProfile.end_date)
  }
  if (discountProfile && discountProfile.code && discountProfile.id) {
    discountProfile.current_code = discountProfile.code
  }

  useEffect(() => {
    if (discountProfile && Object.keys(discountProfile).length) {
      const {
        store_ids,
        quantity,
        quantity_per_customer,
        minimum_value,
        start_date,
        end_date,
        published_at,
        type
      } = discountProfile

      form.resetFields()
      setType(type)
      setShowNumberOfUsesLimit(quantity > 0)
      setShowNumberOfUsesPerCustomerLimit(quantity_per_customer > 0)
      setShowMinimumOrderValue((minimum_value || 0) > 0)
      if (start_date || end_date) {
        setShowDurationLimit(moment(start_date || end_date).isValid())
      }
      if (published_at) {
        setIsActive(moment(published_at).isValid())
      }
      if (store_ids) {
        setShowStoreLimit(!!store_ids.length)
      }
      setTarget(discountProfile.target)
      setIsAutomatic(discountProfile.trigger === 'automatic')
      setStoreIds(store_ids)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountProfile])

  useEffect(() => {
    if (typeValue < 100 && target === 'all_products') {
      const store_min_order_value_rule = form.getFieldValue(
        'store_min_order_value_rule'
      )

      if (store_min_order_value_rule === 'post_discounted_basket_value') {
        form.setFieldsValue({
          store_min_order_value_rule: 'pre_discounted_basket_value'
        })
      }
    }
  }, [typeValue])

  useEffect(() => {
    if (!showStoreLimit) {
      form.setFieldsValue({ store_ids: [] })
      setStoreIds([])
    }
  }, [form, showStoreLimit])

  useEffect(() => {
    if (target === 'delivery_fee') {
      const updatedOptions = fulfillmentTypes.map((type) => {
        if (type.value == 'delivery') return { ...type }

        return {
          ...type,
          disabled: true
        }
      })
      setFulfillmentTypes(updatedOptions)

      const current = form.getFieldValue('fulfillment_types') || []

      form.setFieldsValue({
        fulfillment_types: current.filter((type) => type == 'delivery'),
        store_min_order_value_rule: 'post_discounted_basket_value'
      })
    } else {
      setFulfillmentTypes(FULFILLMENT_TYPE_OPTIONS)
    }
  }, [target])

  const toggleDiscountType = (discountType: string) => {
    setType(discountType)
  }

  const ModalContents = () => {
    const storesWithPricingBands = stores.filter(({ id }: Store) =>
      merchantStoresDeliveryPricingBands.includes(id)
    )
    return (
      <>
        <Row className='_mb-6'>
          <Col>
            There is an active delivery pricing configuration assigned to:
          </Col>
        </Row>
        {storesWithPricingBands.map(({ name }) => (
          <Row className='_mb-0'>
            <Col>
              <Text strong>{name}</Text>
            </Col>
          </Row>
        ))}
        <Row className='_mb-0 _mt-6'>
          <Col>Therefore you will not be able to save this discount.</Col>
        </Row>
      </>
    )
  }

  const onFinish = (values: FormStore) => {
    const isDiscountForAllStores = storeIds.length === 0
    const isTargetDeliveryFee = values.target === 'delivery_fee'
    const isLocationLimitWithDeliveryPricingBands =
      !!locationLimitWithDeliveryPricingBands.length &&
      isTargetDeliveryFee &&
      isAutomatic
    const isMerchantWithActiveDeliveryPricingBands =
      isDiscountForAllStores &&
      !!merchantStoresDeliveryPricingBands.length &&
      isTargetDeliveryFee &&
      isAutomatic

    if (
      isLocationLimitWithDeliveryPricingBands ||
      isMerchantWithActiveDeliveryPricingBands
    )
      return CustomModal({
        type: 'warning',
        content: <ModalContents />
      })

    const {
      id,
      code,
      target,
      type,
      value,
      quantity,
      quantity_per_customer,
      minimum_value,
      start_date,
      end_date,
      store_ids,
      categories,
      products, // {category_id: [product_ids]} this is the shape
      description,
      store_min_order_value_rule,
      fulfillment_types
    } = values

    const defaultDescription = isEmpty(description)
      ? descriptionText({ value, type, target })
      : description

    form.validateFields().then(() => {
      const variables = {
        code,
        merchant_id: merchant.id,
        target: target.toUpperCase(),
        trigger: isAutomatic ? 'AUTOMATIC' : 'MANUAL',
        type: type.toUpperCase(),
        value,
        description: defaultDescription,
        quantity: quantity ? parseInt(quantity) : 0,
        quantity_per_customer: quantity_per_customer
          ? parseInt(quantity_per_customer)
          : 0,
        start_date:
          start_date && start_date.isValid()
            ? start_date.format('YYYY-MM-DD')
            : null,
        end_date:
          end_date && end_date.isValid() ? end_date.format('YYYY-MM-DD') : null,
        minimum_value: minimum_value || 0,
        published_at: isActive ? moment() : null,
        store_ids: store_ids,
        product_ids: uniq(flatten(Object.values(products || {}))),
        category_ids: categories,
        store_min_order_value_rule: store_min_order_value_rule.toUpperCase(),
        fulfillment_types: fulfillment_types.map((type: string) =>
          type.toUpperCase()
        )
      }

      message.loading(
        `${id ? 'Updating' : 'Creating'} discount. Please wait.`,
        1
      )

      const discountMutation =
        action === 'update' ? updateDiscount : createDiscount

      discountMutation({
        variables: !!id ? { discount_id: id, ...variables } : variables
      })
        .then(() => {
          history.replace('/discounts')
          setDrawerVisibility(false)
          message.destroy()
          message.success(`Discount code ${action}d.`, 1)
        })
        .catch((error: Error) => {
          throw errorReducer({
            origin: 'Discount',
            data: {
              error: error,
              message: error.message
            }
          })
        })
        .catch((error) => {
          message.destroy()
          message.error(error.message, 3)
        })
    })
  }

  return (
    (!loading && (
      <>
        <PageHeader
          className='form-title _mt-16 _pb-4 _pt-0'
          title={
            <>
              Discount Details
              <Tooltip
                title={
                  <a
                    href='https://support.slerp.com/knowledge/creating-and-managing-discount-codes'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Discount Codes
                  </a>
                }
              >
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </>
          }
        />
        <Paragraph className='_mb-24'>
          Only one discount can be applied to an order, learn more{' '}
          {
            <a
              target='_blank'
              href={DISCOUNT_HELP_URL}
              rel='noreferrer'
              className='_underline'
            >
              here.
            </a>
          }
        </Paragraph>

        <Form
          form={form}
          layout='vertical'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          initialValues={{
            ...initialFormValues,
            ...discountProfile,
            categories: formatCategoryValues(
              discountProfile?.categories_discounts || []
            ),
            products: formatProductValues(
              discountProfile?.products_discounts || []
            )
          }}
        >
          <Form.Item name='id' style={{ display: 'none' }}>
            <Input type='hidden' />
          </Form.Item>
          <Form.Item name='current_code' style={{ display: 'none' }}>
            <Input type='hidden' />
          </Form.Item>
          <Form.Item
            label='Discount code:'
            name='code'
            rules={discountCodeRule}
            className='_mb-32'
          >
            <Input placeholder='PROMO20' style={{ maxWidth: '360px' }} />
          </Form.Item>
          <Form.Item
            label='Discount description:'
            name='description'
            className='_mb-32'
          >
            <TextArea placeholder='Description' style={{ maxWidth: '360px' }} />
          </Form.Item>
          <Row className='_mb-0'>
            <Col span={14}>
              <Form.Item
                label='Applicable to:'
                name='target'
                className='_mb-32'
              >
                <Select
                  data-testid='discount-apply-to'
                  suffixIcon={<SelectDownArrowIcon />}
                  defaultValue={target ? target : 'all_products'}
                  onChange={(target) => setTarget(target)}
                >
                  <Select.Option value={'all_products'}>
                    All Products
                  </Select.Option>
                  <Select.Option value={'delivery_fee'}>
                    Delivery Charge
                  </Select.Option>
                  <Select.Option value={'subtotal_delivery_fee'}>
                    All Products & Delivery Charge
                  </Select.Option>
                  <Select.Option value={'all_charges'}>
                    All Products, Delivery Charge & Additional Fee
                  </Select.Option>
                  <Select.Option value={'category'}>
                    Specific Categories
                  </Select.Option>
                  <Select.Option value={'product'}>
                    Specific Products
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {target === 'all_charges' && (
              <Col span={10}>
                <div style={{ marginTop: '22px' }}>
                  <Typography.Text>
                    Please note that this discount is applied only to products,
                    delivery charge and additional fee. It does not apply to{' '}
                    <a
                      target='_blank'
                      href={TIPPING_URL}
                      rel='noreferrer'
                      className='_underline'
                    >
                      tipping
                    </a>
                    .
                  </Typography.Text>
                </div>
              </Col>
            )}
            {target === 'subtotal_delivery_fee' && (
              <Col span={10}>
                <div style={{ marginTop: '22px' }}>
                  <Typography.Text>
                    Please note that this discount is applied only to products
                    and delivery charge. It does not apply to{' '}
                    <a
                      target='_blank'
                      href={ADDITIONAL_FEE_URL}
                      rel='noreferrer'
                      className='_underline'
                    >
                      additional fee
                    </a>{' '}
                    or{' '}
                    <a
                      target='_blank'
                      href={TIPPING_URL}
                      rel='noreferrer'
                      className='_underline'
                    >
                      tipping
                    </a>
                    .
                  </Typography.Text>
                </div>
              </Col>
            )}
          </Row>
          {target === 'category' && <Categories merchantId={merchant.id} />}
          {target === 'product' && (
            <Products merchantId={merchant.id} form={form} />
          )}
          <Col span={14} className='_mb-24'>
            <FulfillmentTypeSelect
              options={fulfillmentTypes}
              formName={'fulfillment_types'}
              defaultValue={defaultFulfillmentTypes}
            />
          </Col>

          <Row className='_mb-32'>
            <Col span={6}>
              <Form.Item label='Discount value:' name='type' className='_mb-0'>
                <Radio.Group
                  onChange={(e: RadioChangeEvent) => {
                    toggleDiscountType(e.target.value)
                  }}
                >
                  <Space direction='vertical' align='start'>
                    <Radio
                      value={'percentage'}
                      data-testid='discount-value-percentage'
                    >
                      Percentage
                    </Radio>
                    <Radio value={'fixed'} data-testid='discount-value-fixed'>
                      Fixed Amount
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6} className='_pt-24'>
              {type === 'percentage' ? (
                <Form.Item
                  name='value'
                  rules={percentageValueRule}
                  className='_mb-0'
                >
                  <Input
                    {...PERCENTAGE_INPUT_PARAMS}
                    placeholder='Amount'
                    onChange={(v) => setTypeValue(Number(v.target.value || 0))}
                    required
                  />
                </Form.Item>
              ) : (
                <Form.Item name='value' rules={requiredRule} className='_mb-0'>
                  <Input
                    {...CURRENCY_INPUT_PARAMS}
                    placeholder='Amount'
                    required
                  />
                </Form.Item>
              )}
            </Col>
            {target === 'subtotal_delivery_fee' && type === 'fixed' && (
              <Col span={14}>
                <div className='_mt-4'>
                  <Typography.Text>
                    The discount will be applied to the products first before
                    the delivery charge
                  </Typography.Text>
                </div>
              </Col>
            )}
            {target === 'all_charges' && type === 'fixed' && (
              <Col span={14}>
                <div className='_mt-4'>
                  <Typography.Text>
                    The discount will be applied to the products first, then the
                    delivery charge and finally the additional fee.
                  </Typography.Text>
                </div>
              </Col>
            )}
          </Row>

          <Col span={14}>
            <Form.Item
              label={
                <>
                  Location Minimum Order Value:
                  <Tooltip
                    title={
                      <>
                        If a location has a minimum order value, decide how you
                        would like to calculate whether the customer has met the
                        criteria. <br /> <br />
                        You can choose whether they have met the locations'
                        minimum order value based on the following criteria:{' '}
                        <br /> <br />
                        <ol start={1}>
                          <li>
                            Pre-Discounted Basket Value - The total value of
                            products before the discount was applied.
                          </li>
                          <li>
                            Post-Discounted Basket Value - The total value of
                            products after the discount was applied.
                          </li>
                          <li>
                            Ignore - Bypass the location’s minimum value
                            altogether when the discount is applied.
                          </li>
                        </ol>
                        Note: This is different from the Discount’s “Minimum
                        order value” field which determines the minimum spend
                        required before a discount can be applied.
                      </>
                    }
                  >
                    <QuestionCircleOutlined className='_ml-8' />
                  </Tooltip>
                </>
              }
              name='store_min_order_value_rule'
              className='_mb-40'
              rules={target != 'delivery_fee' ? locationMinOrderValueRule : []}
            >
              <Select
                data-testid='store-minimum-order-value'
                suffixIcon={<SelectDownArrowIcon />}
                disabled={target == 'delivery_fee'}
              >
                <Select.Option value={'pre_discounted_basket_value'}>
                  Pre-Discounted Basket Value
                </Select.Option>
                {(typeValue < 100 || target !== 'all_products') && (
                  <Select.Option value={'post_discounted_basket_value'}>
                    Post-Discounted Basket Value
                  </Select.Option>
                )}
                {/* Hide for now until checkout is ready */}
                {/* <Select.Option value={'ignore_min_order_value'}>
                  Ignore
                </Select.Option> */}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Divider />
          </Col>

          <PageHeader
            className='form-title _mt-16 _pb-0 _pt-0 _mb-24'
            title='Apply Limitations'
          />
          <Col span={24} className='_mb-24'>
            <Space direction='vertical' size={20}>
              <Space align='center'>
                <Switch
                  data-testid='limit-duration-switch'
                  onChange={setShowDurationLimit}
                  checked={showDurationLimit}
                />
                <span>Limit duration</span>
                <Tooltip
                  title={
                    <span>
                      This will show under your 'Scheduled' discount codes.
                    </span>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
              {showDurationLimit && (
                <Space size={20}>
                  <Form.Item
                    data-testid='limit-duration-start-date-field'
                    name='start_date'
                    rules={startDateRule}
                  >
                    <DatePicker
                      format='DD-MM-YYYY'
                      placeholder='Start date'
                      style={{ width: 170 }}
                      onChange={() => {
                        form.validateFields()
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    data-testid='limit-duration-end-date-field'
                    name='end_date'
                    rules={endDateRule}
                  >
                    <DatePicker
                      format='DD-MM-YYYY'
                      placeholder='No end date'
                      style={{ width: 170 }}
                      onChange={() => {
                        form.validateFields()
                      }}
                    />
                  </Form.Item>
                </Space>
              )}
            </Space>
          </Col>

          <Col span={24} className='_mb-24'>
            <Space direction='vertical' size={20}>
              <Space align='center'>
                <Switch
                  data-testid='limit-use-switch'
                  onChange={setShowNumberOfUsesLimit}
                  checked={showNumberOfUsesLimit}
                />
                <span>Limit uses</span>
                <Tooltip
                  placement='right'
                  title='Limit the number of times this discount can be used in total.'
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
              {showNumberOfUsesLimit && (
                <Col span={12}>
                  <Form.Item
                    data-testid='limit-use-field'
                    name='quantity'
                    rules={requiredRule}
                  >
                    <Input
                      type='number'
                      min={0}
                      onFocus={onFocusScrollNumber}
                      onBlur={onBlurScrollNumber}
                    />
                  </Form.Item>
                </Col>
              )}
            </Space>
          </Col>

          <Col span={24} className='_mb-24'>
            <Space direction='vertical' size={20}>
              <Space align='center'>
                <Switch
                  data-testid='limit-use-per-customer-switch'
                  onChange={setShowNumberOfUsesPerCustomerLimit}
                  checked={showNumberOfUsesPerCustomerLimit}
                />
                <span>Limit uses per customer</span>
                <Tooltip
                  placement='right'
                  title={
                    <>
                      Use this limitation to set a limit for each individual
                      customer, so that they cannot exceed the maximum you enter
                      below.{' '}
                      <b>
                        Once enabled, customers must have an account created and
                        be logged in, in order to benefit from the discount.
                      </b>
                    </>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
              {showNumberOfUsesPerCustomerLimit && (
                <Col span={12}>
                  <Form.Item
                    data-testid='limit-use-per-customer-field'
                    name='quantity_per_customer'
                    rules={requiredRule}
                  >
                    <Input
                      type='number'
                      min={0}
                      onFocus={onFocusScrollNumber}
                      onBlur={onBlurScrollNumber}
                    />
                  </Form.Item>
                </Col>
              )}
            </Space>
          </Col>

          <Col span={12} className='_mb-24'>
            <Space direction='vertical' size={20}>
              <Space align='center'>
                <Switch
                  onChange={setShowStoreLimit}
                  checked={showStoreLimit}
                  data-testid='limit-location-switch'
                />
                <span>Limit to location</span>
              </Space>
            </Space>
            {showStoreLimit && (
              <Form.Item name='store_ids'>
                <Select
                  className='_mt-24'
                  mode='multiple'
                  allowClear
                  placeholder='Choose locations'
                  dropdownClassName='select-multiple'
                  filterOption={(input: string, option: StoreSwitchOption) =>
                    filterSelectOption(input, option)
                  }
                  onChange={(values: string[]) => setStoreIds(values)}
                >
                  {stores.map((store: Store) => {
                    const isDisabled =
                      target === 'delivery_fee'
                        ? merchantStoresDeliveryPricingBands.includes(store.id)
                        : false
                    return (
                      <Select.Option
                        key={store.id}
                        value={store.id}
                        disabled={isDisabled}
                      >
                        {store.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            )}
          </Col>

          <Col span={12} className='_mb-24'>
            <Space direction='vertical' size={20}>
              <Space align='center'>
                <Switch
                  onChange={setShowMinimumOrderValue}
                  checked={showMinimumOrderValue}
                  data-testid='minimum-order-value-switch'
                />
                <span>Minimum order value</span>
                <Tooltip
                  placement='right'
                  title={
                    <span>
                      Set a minimum amount your customers must spend to receive
                      the discount when they enter the code.
                      <br />
                      <br /> We recommend displaying this code on your store's
                      promotional banner.
                    </span>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
              {showMinimumOrderValue && (
                <Form.Item name='minimum_value' rules={requiredRule}>
                  <Input {...CURRENCY_INPUT_PARAMS} />
                </Form.Item>
              )}
            </Space>
          </Col>

          <Col span={12}>
            <Divider />
          </Col>

          <PageHeader
            className='form-title _mt-16 _pb-0 _pt-0 _mb-24'
            title='Discount Settings'
          />
          <Col span={12} className='_mb-24'>
            <Space direction='vertical' size={20}>
              <Space align='center'>
                <Switch
                  data-testid='automatic-discount-switch'
                  onChange={setIsAutomatic}
                  checked={isAutomatic}
                />
                <span>Apply discount automatically</span>
                <Tooltip
                  title={
                    <span>
                      If a discount is set to automatic it will be instantly
                      applied to the cart at checkout. Meaning customers won't
                      need to input a discount code manually.
                      <br />
                      <br /> These types of discount codes are great to promote
                      during seasonal periods as they drive traffic to your
                      stores. For example, all hamper orders over £100 get a
                      free bottle of champagne this festive season.
                    </span>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
            </Space>
          </Col>

          <Col span={12} className='_mb-24'>
            <Space direction='vertical' size={20}>
              <Space align='center'>
                <Switch
                  onChange={setIsActive}
                  checked={isActive}
                  data-testid='activate-discount-switch'
                />
                <span>Activate Discount</span>
              </Space>
            </Space>
          </Col>

          <Divider />
          <Col span={24}>
            <Row gutter={16}>
              <Col>
                <Button
                  onClick={() => setDrawerVisibility(false)}
                  type='ghost'
                  className='_uppercase'
                  data-testid='discount-discard-button'
                >
                  <Link to='/discounts/codes'>Discard</Link>
                </Button>
              </Col>
              <Col>
                <Button
                  title={form.getFieldValue('id') ? 'Update' : 'Add'}
                  type='primary'
                  htmlType='submit'
                  disabled={isLoadingCreateDiscount || isLoadingUpdateDiscount}
                  className='_uppercase'
                  data-testid={`discount-${
                    form.getFieldValue('id') ? 'update' : 'add'
                  }-button`}
                >
                  {`${form.getFieldValue('id') ? 'Update' : 'Add'} Discount`}
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </>
    )) || <Loading />
  )
}

export default DiscountForm
